<script lang="ts" setup>
import type { AvailablePurchaseOptionFixedPriceTicketDto } from '@peter-park/available-purchase-option'
import type { ShopInfoDto, UserShopGroupDto } from '@peter-park/shop'
import { useGetPurchaseOptionsForShop } from '@/composables/openapi/availablePurchaseOption/useGetPurchaseOptionsForShop'
import { useTicketsAreas } from '@/composables/useTicketsAreas'
import { userSettingsStore } from '@/stores'
import { logSelectTicket } from '@/utils/logger'
import { ticketHasAllAreas } from '@/utils/ticket'
import { storeToRefs } from 'pinia'

const { areaEntityId, shop, availableUserShopGroups } = defineProps<{
  areaEntityId?: string
  shop: ShopInfoDto
  availableUserShopGroups: UserShopGroupDto[]
}>()
const selectedAreaIds = ref<Array<string>>([])

const userSettings = userSettingsStore()
const { latestShopVisited } = storeToRefs(userSettings)
const router = useRouter()
const enabled = computed(() => {
  return !!areaEntityId
})
// if the area entity id is not available, we keep the `isEnabled` flag as false
const { purchaseOptionsForShop, suspenseGetPurchaseOptionsForShop } = useGetPurchaseOptionsForShop(areaEntityId, enabled)
await suspenseGetPurchaseOptionsForShop()

const fixedDurationTickets = computed((): AvailablePurchaseOptionFixedPriceTicketDto[] => {
  return purchaseOptionsForShop?.value?.fixedDurationTickets ?? []
})

const { ticketsAreas } = useTicketsAreas(fixedDurationTickets)

const isMultipleAreas = computed(() => ticketsAreas.value.length > 1)

// show only the tickets where the selected areas are included
const filteredTickets = computed(() => {
  const areaIds = selectedAreaIds.value
  if (!isMultipleAreas.value || areaIds.length === 0)
    return fixedDurationTickets.value
  return fixedDurationTickets.value.filter(ticket => ticketHasAllAreas(ticket, areaIds))
})

const { t } = useI18n()

function handleViewTicket(ticketId: string) {
  logSelectTicket(ticketId)
  router.push({ name: 'view-ticket', params: { ticketId, shop: latestShopVisited.value } })
}

function handleSelectUserGroup(groupId: string) {
  router.push({ name: 'configure-long-term-parker', params: { groupId, shop: shop.path } })
}
</script>

<template>
  <div class="w-full flex flex-col items-center gap-4">
    <div v-if="isMultipleAreas" class="w-full flex justify-between rounded-4 bg-gray-25 p-4">
      <UiAreaSelection v-model="selectedAreaIds" :areas="ticketsAreas" allow-unselect />
      <div class="flex flex-col text-right">
        <span class="text-3.5 text-gray-900 leading-4.5">{{ t('showing') }}</span>
        <span class="text-3.5 text-gray-200 leading-4.5">{{ t('tickets', filteredTickets.length) }}</span>
      </div>
    </div>
    <div class="bg-dotted min-h-lg w-full p-10">
      <TicketsGrid
        :tickets="filteredTickets"
        :user-shop-groups="availableUserShopGroups"
        @selected="handleViewTicket"
        @select-user-group="handleSelectUserGroup"
      />
    </div>
  </div>
</template>

<style scoped>
.bg-dotted {
  background-image: url("@/assets/dottedCardBackground.svg")
}
</style>
